.mainDrawer{
    background: #F5F3F8;
            min-height: 100vh;
            
            width: 250px;
    .parent{
        padding: 30px;
        .user{
            text-align: center;
            margin-top: 30px;
            .userLogo{
                margin: 0 auto;
                background-color: #fff;
                    border-radius: 50%;
                    width: 64px;
                    height: 64px;
                img{
                    border-radius: 50%;
                    display: block;
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
            h4{
                font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 120%;
                    color: #363352;
                    margin-top: 15px;
            }
            h5{
                font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                
                    color: #363352;
            }
        }
        .menuList{
            margin-top: 50px;
            ul{
                li{
                    cursor: pointer;
                    .menuItem.active{
                        &:hover{
                            background: #5F5FC1;
                                border-radius: 8px;
                            
                                svg {
                                    path {
                            
                                        fill: #fff;
                                    }
                                }
                            
                                h6 {
                            
                                    color: #FFFFFF;
                                }
                        }
                    }
                    .menuItem{
                        display: flex;
                        align-items: center;
                            height: 40px;
                        padding: 10px 14px;
                            border-radius: 8px;
                        h6{

                            font-family: 'Poppins';
                                font-style: normal;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 30px;
                                                            color: #000;
                        }
                        
                            
                        
                        svg{
                            margin-right: 23px;
                           path{
fill: #000;
                           } 
                        }
                        &:hover{
                            background-color: rgba(0,0,0,0.2);
                        }
                    }
                    .active{
                           
                            background: #5F5FC1;
                            border-radius: 8px;
                        svg{
                            path{

                                fill: #fff;
                            }
                        }
                        h6{

                            color: #FFFFFF;
                        }
                       
                    }
                    margin-bottom: 20px;
                }
            }
        }
    }
}