@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
    background-color: #FFFFFF !important;
    color: #555 !important;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    -webkit-text-fill-color: #555555 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

*{
  font-family: "Poppins", sans-serif;}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

// custom slick design

.slick-dots li{
  margin: 0 3px !important;
}

.slick-dots li button:before{
  font-size: 12px !important;
}
.slick-dots{
  position: unset !important;
  margin-top: 25px !important;
}
.errorMSG{
  color: tomato;
}
.desktoperror{
  display: block;
  @media (max-width:'991px'){
    display: none;
  }
}
.mobileerror{
  display: none;
  @media (max-width:'991px'){
    display: block;
  }
}


// custom wrap

.customCheckbox{
  position: relative;
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 2px;
  input{
    position: absolute;
    opacity: 0;
    z-index: 3;
  }
  svg{
    position: absolute;
    display: none;
    top: 8px;
    right: 30%;
  }

  input[type="checkbox"]:checked + svg{
    display: block;
  }


}             

// top nav
.szh-menu{
  width: 250px !important;
  border-radius: 20px !important;
  margin-top: 6px !important;
  background: #FFFFFF !important;
    border: 2px solid #F5F3F8 !important;
}
.otpContrainer{
  div{
    div{
      margin-right: 10px;
      input{
        width: 40px !important;
        height: 40px;
        &:focus{
          outline: none;
        }
      }
    }
  }
  span{
    display: none;
  }
}
