.wrapper{
    .parent{
display: grid;
grid-template-columns: 250px 1fr;
.bg{
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    height: 100%;
    z-index: 5;
    transition: .5s all;
}
        .menu{
           .webMenu{
               position: fixed;
               left: 0;
               top: 0;
           }
        }
        .mobileMenu{
            display: none;
            z-index: 10;
        }
        .aside{
    width: 100%;
    min-height: 100vh;
    padding: 30px 140px;
    @media all and (max-width: 1800px) {
padding: 30px 30px !important;
    }
        }
        @media all and (max-width: 1024px) {
grid-template-columns:  1fr;
.aside{
    padding: 20px !important;
}
.menu{
    display: none;
}
.mobileMenu {
    display: block;
    transform: translateX(-100%);
    position: fixed;
    left: 0;
    top: 0;
    transition: .5s all;
}
            
        }
    }
}