.dashboard {
  .parent {
    display: grid;
    // grid-template-columns:1.5fr minmax(180px, 0.5fr) minmax(280px, 0.7fr);
    grid-template-columns:
      1.1fr 1fr minmax(120px, 0.4fr) minmax(120px, 0.4fr) minmax(140px, 0.4fr)
      minmax(140px, 0.4fr);
    // grid-template-columns: auto auto auto auto;
    grid-template-rows: 0.5fr 0.8fr auto auto auto;
    grid-template-areas:
      "todayHighLight todayHighLight todayHighLight todayHighLight creditBalance creditBalance"
      "customerAnalysis customerAnalysis campaignBox campaignBox creditBalance creditBalance"
      "customerStatics customerStatics actionBox actionBox actionBox actionBox"
      "customerSegment customerSegment customerSegment customerSegment loyalty loyalty"
      "campaignsSent campaignsSent bestContributors bestContributors bestContributors bestContributors";

    @media all and (max-width: 1400px) {
      grid-template-rows: 0.5fr 0.7fr auto auto auto auto;
      grid-template-columns:
        1.1fr 1fr minmax(120px, 0.4fr) minmax(120px, 0.4fr) minmax(120px, 0.4fr)
        minmax(120px, 0.4fr);
      grid-template-areas:
        "todayHighLight todayHighLight todayHighLight todayHighLight creditBalance creditBalance"
        "customerAnalysis customerAnalysis customerAnalysis customerAnalysis creditBalance creditBalance"
        "campaignBox campaignBox campaignBox campaignBox campaignBox campaignBox"
        "customerStatics customerStatics customerStatics customerStatics customerStatics customerStatics"
        "actionBox actionBox actionBox actionBox actionBox actionBox"
        "customerSegment customerSegment customerSegment customerSegment loyalty loyalty"
      "campaignsSent campaignsSent bestContributors bestContributors bestContributors bestContributors";
    }
    @media all and (max-width: 1200px) {
      grid-template-columns:
        1.1fr 1fr minmax(120px, 0.4fr) minmax(120px, 0.4fr) minmax(115px, 0.4fr)
        minmax(115px, 0.4fr);
      grid-template-areas:
        "todayHighLight todayHighLight todayHighLight todayHighLight creditBalance creditBalance"
        "customerAnalysis customerAnalysis customerAnalysis customerAnalysis creditBalance creditBalance"
        "campaignBox campaignBox campaignBox campaignBox campaignBox campaignBox"
        "customerStatics customerStatics customerStatics customerStatics customerStatics customerStatics"
        "actionBox actionBox actionBox actionBox actionBox actionBox"
        "customerSegment customerSegment customerSegment customerSegment customerSegment customerSegment"
        "loyalty loyalty loyalty loyalty loyalty loyalty"
      "campaignsSent campaignsSent campaignsSent campaignsSent campaignsSent campaignsSent"
      "bestContributors bestContributors bestContributors bestContributors bestContributors bestContributors";
    }
    @media all and (max-width: 819px) {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "todayHighLight todayHighLight"
        "creditBalance creditBalance "
        "customerAnalysis customerAnalysis "
        "campaignBox campaignBox "
        "customerStatics customerStatics "
        "actionBox actionBox "
        "customerSegment customerSegment"
        "loyalty loyalty"
        'campaignsSent campaignsSent'
        'bestContributors bestContributors';
    }

    grid-gap: 20px;
    .gridItem {
      &:nth-child(1) {
        grid-area: todayHighLight;
      }
      &:nth-child(2) {
        grid-area: creditBalance;
      }
      &:nth-child(3) {
        grid-area: customerAnalysis;
      }
      &:nth-child(4) {
        grid-area: campaignBox;
      }
      &:nth-child(5) {
        grid-area: customerStatics;
      }
      &:nth-child(6) {
        grid-area: actionBox;
      }
      &:nth-child(7) {
        grid-area: customerSegment;
      }
      &:nth-child(8) {
        grid-area: loyalty;
      }
      &:nth-child(9) {
        grid-area: campaignsSent;
      }
             &:nth-child(10) {
               grid-area: bestContributors;
             }

            
    }
    // &:nth-child(9) {
    //   grid-area: todayHighLight;
    // }&:nth-child(10) {
    //   grid-area: todayHighLight;
    // }
    @media all and (max-width: 1380px) {
      // grid-template-rows: 0.5fr 0.6fr auto auto auto;
    }
    @media all and (max-width: 1020px) {
      // grid-template-columns: 1fr !important;
      // grid-template-rows: auto auto auto auto auto;
      // grid-row-gap: 20px;
      // grid-column-gap: 0;
    }

    grid-gap: 20px;
    margin-top: 30px;
    @mixin dashCard() {
      background: #ffffff;
      box-shadow: -1px 5px 30px rgba(0, 0, 0, 0.13);
      border-radius: 22px;
      padding: 30px;
      overflow: hidden;
      height: 100%;
      @media all and (max-width: 1100px) {
        padding: 20px !important;
      }
      .box {
        .head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .title {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 105%;
            color: #363352;
          }
          .actions {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .ctaLink {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 21px;
              text-decoration-line: underline;
              cursor: pointer;
              color: #363352;
            }
          }
        }
        .subHead{
          margin-top: 35px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          h5{
font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #363352;
          }
          h6{
font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;

  color: #363352;
          }
        }
      }
    }
    // today highlights card
    .todayHighlights {
      // grid-area: 1 / 1 / 2 / 3;
      @include dashCard();
      @media all and (max-width: 1380px) {
        .actions {
          justify-content: flex-end;
          .suggestItem {
            margin-bottom: 15px !important;
          }
        }
      }
      @media all and (max-width: 1020px) {
        // grid-area: 1 /1/ 2/ 1 !important;
      }
      .box {
        .head {
          .actions {
            .dateSuggested {
              display: flex;
              align-items: center;
              .suggestItem {
                cursor: pointer;
                background: #f5f3f8;
                border-radius: 8px;
                padding: 5px 20px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #363352;
                margin-left: 12px;
                white-space: nowrap;
              }
            }
            .dateCustom {
              @extend .dateSuggested;
              margin-left: 20px;
              .customItem {
                margin-left: 12px;
                cursor: pointer;
                padding: 5px 20px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #363352;
                background: #ffffff;
                border: 1px solid #a3a1be;
                border-radius: 8px;
              }
            }
          }
        }
        .highlightCounts {
          display: grid;
          margin-bottom: 30px;
          margin-top: 60px;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          align-items: center;

          .countItem {
            h5 {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 110%;
              color: #363352;
            }

            h6 {
              margin-top: 13px;
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 30px;
              line-height: 110%;
              color: #363352;
            }
          }
        }
      }
    }
    // credit balance card
    .creditBalance {
      // grid-area: 1 / 3 / 3 / 4;
      @include dashCard();
      @media all and (max-width: 1380px) {
      }
      @media all and (max-width: 1020px) {
        // grid-area: 2 /1/ 3/ 1 !important;
      }
      .head {
        margin-bottom: 42px;
      }
      .creditCounts {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        .count {
          h5 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 110%;
            color: #363352;
          }
          h6 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 110%;
            color: #363352;
          }
        }
        .action {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 21px;
          cursor: pointer;
          color: #e81a6e;
        }
      }
      .progressStatus {
        margin-top: 30px;
        .progressItem {
          margin-bottom: 37px;
          h5 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            color: #77777b;
            // margin-bottom: 6px;
          }
          .bar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            svg {
              width: 100%;
            }
            h6 {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 21px;
              color: #e81a6e;
              margin-left: 12px;
            }
          }
        }
      }
    }
    // customer analysis
    .customerAnalysis {
      @include dashCard();
      // grid-area: 2 / 1 / 3 / 2;
      @media all and (max-width: 1380px) {
        // grid-area: 2/1/3/3;
      }
      @media all and (max-width: 1020px) {
        // grid-area: 3 /1/ 4/ 1 !important;
      }
      .circleProgress {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-top: 42px;
        @media all and (max-width: 600px) {
          .circleItem {
            max-width: 90px !important;
          }
        }
        .circleItem {
          text-align: center;
          max-width: 130px;
          margin: 0 auto;
          .circle {
            position: relative;
            margin-bottom: 26px;
            svg {
              circle {
                stroke-width: 10px;
              }
            }
          }
          .count {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 120%;

            color: #363352;
          }
          h5 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 120%;
            color: #363352;
          }
        }
      }
    }
    // campaign card
    .campaignBox {
      @include dashCard();
      // grid-area: 2 / 2 / 3 / 3;
      @media all and (max-width: 1380px) {
        // grid-area: 3/1/4/4;
        .box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          button {
            margin: 0 !important;
          }
        }
      }
      @media all and (max-width: 1020px) {
        //  grid-area: 4 /1/ 5/ 1 !important;
      }
      background: linear-gradient(180deg, #5f5fc1 0%, #e81a6e 100%);
      .box {
        h2 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 120%;
          color: #ffffff;
        }
        h3 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 76px;
          line-height: 120%;
          color: #ffffff;
          margin: 5px 0;
        }
        button {
          margin-top: 30px;
          background: #000000;
          border-radius: 40px;
          padding: 9px 19px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 21px;
          color: #ffffff;
        }
      }
    }
    // customerStatics
    .customerStatics {
      @include dashCard();
      // grid-area: 3 / 1 / 4 / 2;
      @media all and (max-width: 1380px) {
        // grid-area: 4/1/4/4;
        .staticBox {
          margin-top: 40px !important;
        }
      }
      @media all and (max-width: 1020px) {
        // grid-area: 5 /1/ 6/ 1 !important;
      }
      .staticBox {
        margin-top: 80px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 30px;
        .staticItem {
          text-align: center;
          h3 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 110%;
            color: #363352;
          }
          h4 {
            font-family: "Poppins";
            margin-top: 14px;
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 110%;
            color: #363352;
          }
          button {
            margin-top: 50px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 21px;
            background: #e81a6e;
            border-radius: 40px;
            padding: 8px 25px;
            color: #ffffff;
          }
        }
      }
    }

    // take action
    .takeAction {
      @include dashCard();
      // grid-area: 3 / 2 / 4 / 4;
      @media all and (max-width: 1380px) {
        // grid-area: 5 / 1 / 5 / 4;
      }
      @media all and (max-width: 1020px) {
        // grid-area: 6 /1/ 7/ 1 !important;
      }
      .actionsList {
        .actionItem {
          margin-top: 26px;
          border-radius: 10px;
          display: grid;
          grid-template-columns: 0.7fr 1fr;
          align-items: center;
          min-height: 60px;
          background: #f5f3f8;
          @media all and (max-width: 600px) {
            .left {
              font-weight: 600 !important;
              font-size: 15px !important;
              padding: 10px 10px !important;
            }
            .right {
              padding: 10px 10px !important;
            }
          }
          &:hover {
            .left {
              background: #e81a6e;
              color: #fff;
            }
            .right {
              svg {
                path {
                  fill: #000;
                }
              }
            }
          }
          .left {
            padding: 12px 30px;
            border-radius: 10px;
            cursor: pointer;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 120%;
            color: #363352;
          }
          .right {
            padding: 12px 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            h5 {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;

              color: #363352;
            }
            svg {
              margin-left: 15px;
              cursor: pointer;
              path {
                fill: #bd98a3;
              }
            }
          }
        }
      }
    }
    .customerSegmentation {
      @include dashCard();
      // grid-area: 4 / 1 / 5 / 3;
      @media all and (max-width: 1380px) {
        // grid-area: 8 / 1 / 6 / 4;
      }
      @media all and (max-width: 1020px) {
        // grid-area: 8 /1/ 8/ 1 !important;
      }
      .segments {
        margin-top: 65px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        gap: 45px;
        @media all and (max-width: 600px) {
          gap: 25px !important;
        }
        .segmentItem {
          background: #ffffff;
          box-shadow: -1px 5px 30px rgba(0, 0, 0, 0.13);
          border-radius: 28px;
          &:nth-child(1) {
            background: #894ce5;
            .bgLayer {
              background: #894ce5;
            }
          }
          &:nth-child(2) {
            background: #894ce5;
            .bgLayer {
              background: #894ce5;
            }
          }
          &:nth-child(3) {
            background: #e81a6e;
            .bgLayer {
              background: #e81a6e;
            }
          }
          .top {
            padding: 20px;
            border-radius: 28px;
            position: relative;
            .bgLayer {
              position: absolute;
              display: inline-block;
              width: 100%;
              height: 18px;
              right: 0;
              left: 0;
              bottom: -11px;
              border-radius: 0 0 28px 28px;
            }
            .head {
              h3 {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 120%;
                /* or 19px */

                display: flex;
                align-items: center;
                text-align: center;

                color: #ffffff;
              }
            }
            .count {
              margin-top: 48px;
              display: flex;
              justify-content: flex-end;
              h4 {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 700;
                font-size: 60px;
                line-height: 120%;

                color: #ffffff;
              }
            }
          }
          .bottom {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 21px;
            border-radius: 0 0 28px 28px;

            text-align: center;

            color: #363352;
            background: #ffffff;
            box-shadow: -1px 5px 30px rgba(0, 0, 0, 0.13);
            padding: 22px 0;
          }
        }
      }
    }
    // loyaltyPoints
    .loyaltyPoints {
      @include dashCard();
      .points {
        margin-top: 42px;
        .pointItem {
          margin-bottom: 32px;
          &:last-child {
            margin-bottom: 0;
          }
          h4 {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 120%;
            color: #363352;
          }
          h5 {
            margin-top: 6px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 120%;
            color: #e81a6e;
          }
        }
      }
      h6 {
        margin-top: 32px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        text-decoration-line: underline;
        cursor: pointer;
        color: #363352;
      }
    }
    // campaignsSent
    .campaignsSent {
      @include dashCard();
      .campaigns{
        margin-top: 50px;
        .campaignItem{
          margin-bottom: 30px;
          &:last-child{
            margin: 0;
          }
          display: flex;
          justify-content: space-between;
          padding: 20px;
          background: #FFFFFF;
            mix-blend-mode: normal;
            border: 2px solid #F9F9F9;
            border-radius: 10px;
          .left{
h3 {
font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #363352;
}

  .bottom{
    margin-top: 12px;
    display: flex;
    align-items: center;
    h4{
font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
 
    }
    h5{
font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-left: 8px;
  color: #363352;
    }
  }
}
.right{
  text-align: end;
  h3{
font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
 
  color: #363352;
  }
  h4{
font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */

  display: flex;
  align-items: center;

  color: #363352;
  }
}
          }
        }
        .footer{
          margin-top: 30px;
          text-align: end;
          h6{
font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  
  text-decoration-line: underline;

  color: #363352;
          }
        }
      }
            // bestContributors
            .bestContributors{
              @include dashCard();
              .contributors{
                margin-top: 20px;
                display: grid;
                grid-template-columns: 1fr;
                grid-row-gap: 23px;
                .contribItem{
                  display: grid;
                  grid-template-columns: 60px auto 100px;
                  grid-column-gap: 15px;
                align-items: center;
                  .profile{
                    width: 60px;
                      height: 60px;
border-radius: 50%;
background-color: #a3a1be;
                    img{
width: 100%;
display: block;
border-radius: 50%;
                    }
                  }
                  .info{
                    h5{
font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: #363352;
                    }
                    h6{
font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
margin-top: 3px;
  color: #363352;
                    }
                  }
                  .value{
text-align: end;
font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #363352;
                  }
                }
              }
            }
    }
  }

